import type { NavItem } from '@nuxt/content'
import _ from 'lodash'

export type Nullable<T> = T | null | undefined

export type SearchItems = Record<string, NavItem>

// transform titles for searching and anchor fragments
export const slugify = (link: string) =>
  link.toLowerCase().trim().replace(/^the /i, '').replaceAll('\n', ' ').replaceAll("'", '').replaceAll(' ', '-')

export const slugifyId = (link: string) => link.toLowerCase().trim().replaceAll("'", '').replaceAll(' ', '-')

export const stringify = (item: any): string => (item === null || item === undefined ? '' : `${item}`)
export const numberify = (item: string | number, fallback: number = 0) => parseInt(item as string) || fallback

// These words should not be capitalized by titleCase
const ignoreWords = new Set([
  'the',
  'of',
  'for',
  'a',
  'an',
  'on',
  'in',
  'at',
  'by',
  'to',
  'but',
  'ft',
  'cp',
  'sp',
  'ep',
  'gp',
  'nan',
  'with',
  'LG',
  'NG',
  'CG',
  'LN',
  'TN',
  'CN',
  'LE',
  'NE',
  'CE',
  'HQ',
])

export function titleCase(...parts: Nullable<string>[]): string {
  return parts
    .filter((p) => !!p)
    .join(' ')
    .replaceAll(/[a-z0-9]+(?:'s?)?/gi, (w) => (ignoreWords.has(w) ? w : _.capitalize(w)))
}

export const joinParts = (delim: string, ...parts: Nullable<string>[]) => parts.filter((p) => !!p).join(delim)

export const ensurePrefix = (text: string, prefix: string) => (text.startsWith(prefix) ? text : `${prefix}${text}`)

export const ensureSuffix = (text: string, suffix: string) => (text.endsWith(suffix) ? text : `${text}${suffix}`)

export const parenthesize = (text: Nullable<string>) => (text ? `(${text})` : '')

export const insertAt = <T>(arr: T[], index: number, item: T): T[] => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
]

export function flattenNavigation(nav: NavItem[]): NavItem[] {
  const concatAll = (node: NavItem): (NavItem | null)[] => {
    const self = Object.assign({}, node)
    const children = self.children

    const res = self._id ? self : null

    if (children) {
      delete self.children
      return [res, ...children.flatMap(concatAll)]
    }
    return [res]
  }
  const allPages = nav.flatMap(concatAll).filter((x) => !!x)
  return allPages
}

export const arrayEquals = (a: unknown[], b: unknown[]) => {
  return (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((val: unknown, index: number) => val === b[index])
  )
}

export const clamp = (num: number, min: number = 0, max: number = Infinity) => {
  if (num < min) return min
  if (num > max) return max
  return num
}

const spellLevels = ['Cantrip', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th']

export const spellLevel = (level: number) => spellLevels[level] || level

export const arrayify = <T>(item: T | T[]): T[] => (Array.isArray(item) ? item : [item])

export const copyToClipboard = (str: string) => {
  const el = document.createElement('textarea')
  el.value = str
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
}

export type CustomNavItemProps = {
  public?: string
  icon?: string
  priority?: number
}

export type Node = NavItem & CustomNavItemProps

export const sortLinks = (items: NavItem[], dm: boolean) => {
  return items
    .filter((page: Node) => page._id === undefined || page.public || dm)
    .sort((a, b) => {
      const prio = (b.priority ?? 0) - (a.priority ?? 0)
      if (prio) return prio
      return a.title.localeCompare(b.title)
    })
}

export const rarityClass = (rarity: string) => rarity.replaceAll(' ', '').toLowerCase()

export const mapKeys = <T>(obj: Record<string, T>, fn: (key: string, val: T) => string): Record<string, T> => {
  return Object.fromEntries(Object.entries(obj).map(([key, val]) => [fn(key, val), val]))
}

export const mapValues = <T, U>(obj: Record<string, T>, fn: (key: string, val: T) => U): Record<string, U> => {
  return Object.fromEntries(Object.entries(obj).map(([key, val]) => [key, fn(key, val)]))
}

export const minmax = <T, E>(items: T[], extract: (item: T) => E = _.identity): E[] => {
  let min = extract(items[0])
  let max = extract(items[0])
  items.forEach((item) => {
    const val = extract(item)
    if (min > val) min = val
    if (max < val) max = val
  })
  return [min, max]
}

export const CURRENT_YEAR = 1434
